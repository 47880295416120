import { useCallback, useState } from "react";
import { useLocalization } from "@fluent/react";

import { ContentCard, DateInput, useNotificationContext } from "cerulean";
import { DateTime } from "luxon";
import ApiHttp from "byzantine/src/ApiHttp";
import { modules } from "byzantine";
import DrawerLayout from "../../../../../DrawerLayout";
import MultiCountryPicker from "../../../MultiCountryPicker";
import BackButton from "../../../../../../src/modules/transfers/wire/templates/TemplatesList/BackButton";
import styles from "./TravelNoticeForm.module.scss";

import * as formAdapters from "../../../../../../src/adapters";

interface TravelNoticeFormProps {
  onUserDismiss: () => void;
  goBack: () => void;
  cardId: API.CardId;
}

const TravelNoticeForm = ({
  onUserDismiss,
  goBack,
  cardId,
}: TravelNoticeFormProps) => {
  const { l10n } = useLocalization();
  const { form, onValidate } = modules.travelNotes.useCreateTravelNoteForm();
  const [saveIsEnabled, setSaveIsEnabled] = useState(true);
  const { sendNotification } = useNotificationContext();

  const addTravelNote = useCallback(async () => {
    setSaveIsEnabled(false);

    if (!(await onValidate())) {
      setSaveIsEnabled(true);
      return;
    }

    const payload = {
      start_date: DateTime.fromFormat(
        form.values.startDate!,
        "M/d/yyyy",
      ).toFormat("yyyy-MM-dd"),
      end_date: DateTime.fromFormat(form.values.endDate!, "M/d/yyyy").toFormat(
        "yyyy-MM-dd",
      ),
      travel_destination_codes: form.values.travelDestinationCodes!,
    };
    try {
      await ApiHttp.fetch(
        `cards/${cardId}/travel_note/`,
        {
          method: "POST",
        },
        payload,
      );
      sendNotification({
        type: "success",
        text: l10n.getString("travel-notice-added-success"),
      });
      onUserDismiss();
    } catch (error) {
      sendNotification({
        type: "negative",
        text: l10n.getString("travel-notice-added-failure"),
      });
    } finally {
      setSaveIsEnabled(true);
    }
  }, [
    form.values.endDate,
    form.values.startDate,
    form.values.travelDestinationCodes,
    onValidate,
  ]);

  const setInvalidStartDates = useCallback(
    (date: Date) => {
      if (!form.values.endDate) {
        return false;
      }
      const endDate = DateTime.fromFormat(form.values.endDate, "M/d/yyyy");
      if (DateTime.fromJSDate(date) > endDate) {
        return true;
      }

      return false;
    },
    [form.values.endDate],
  );

  const setInvalidEndDates = useCallback(
    (date: Date) => {
      if (!form.values.startDate) {
        return false;
      }
      const startDate = DateTime.fromFormat(form.values.startDate, "M/d/yyyy");
      if (DateTime.fromJSDate(date) < startDate) {
        return true;
      }
      return false;
    },
    [form.values.startDate],
  );

  return (
    <DrawerLayout
      onSave={addTravelNote}
      saveLabel={l10n.getString("travel-notice-submit-button")}
      onCancel={onUserDismiss}
      cancelLabel={l10n.getString("travel-notice-cancel-button")}
      isSaveDisabled={!saveIsEnabled}
    >
      <div className={styles.container}>
        <BackButton
          text={l10n.getString("travel-notice-back-button")}
          className="margin--bottom--l"
          onClick={goBack}
        />
        <h2 className={styles.header}>
          {l10n.getString("travel-notice-form-title")}
        </h2>
        <div className="margin--top--xs margin--bottom--xl">
          {l10n.getString("travel-notice-form-description")}
        </div>
        <ContentCard kind="bordered" radiusSize="m">
          <div className="fontColor--heading fontWeight--bold margin--bottom--l">
            {l10n.getString("travel-notice-form-destination-section")}
          </div>
          <MultiCountryPicker
            label={l10n.getString("travel-notice-country-picker")}
            {...formAdapters.fieldWithOnChange(form, "travelDestinationCodes")}
          />
          <div
            className="margin--y--l border--top"
            style={{
              width: "calc(var(--space-l) + 100% + var(--space-l))",
              marginLeft: "calc(-1*var(--space-l))",
            }}
          />
          <div className="fontColor--heading fontWeight--bold margin--bottom--l">
            {l10n.getString("travel-notice-form-dates-section")}
          </div>
          <DateInput
            minDate={DateTime.now().toFormat("MM/dd/yyyy")}
            label={l10n.getString("travel-notice-start-date-input")}
            dateFormat={"m/d/Y"}
            altInput
            altFormat={"m/d/Y"}
            useIsoOnChange={false}
            disableDates={[setInvalidStartDates]}
            endIcon="calendar"
            {...formAdapters.fieldWithOnChange(form, "startDate")}
          />
          <div className="margin--bottom--l" />
          <DateInput
            minDate={DateTime.now().toFormat("MM/dd/yyyy")}
            field="end_date"
            label={l10n.getString("travel-notice-end-date-input")}
            dateFormat={"m/d/Y"}
            altInput
            altFormat={"m/d/Y"}
            useIsoOnChange={false}
            disableDates={[setInvalidEndDates]}
            endIcon="calendar"
            {...formAdapters.fieldWithOnChange(form, "endDate")}
          />
        </ContentCard>
      </div>
    </DrawerLayout>
  );
};

export default TravelNoticeForm;
